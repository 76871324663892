import LocomotiveScroll from 'locomotive-scroll';

const lscroll = new LocomotiveScroll({
    el: document.querySelector('.smooth-scroll'),
    smooth: true,
    repeat: false,
    getDirection:true,
    // resetNativeScroll: true,
    // reloadOnContextChange: true,
    direction: 'horizontal',
    gestureDirection: 'both',
    tablet: { 
        smooth:false,
        direction: "vertical" 
    },
    smartphone: {
        smooth:false, 
        direction: "vertical",
    },
});


export {lscroll};