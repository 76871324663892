import Component from './component';
import Module from './module';

const components = {};
// const services = {};

class App {
	constructor() {
		this.dom = {
			$body: $('body'),
			$html: $('html'),
			$window: $(window),
		};
		
		this.modules = {};

		this.dom.$body.on('owd.tick', (e) => {
			setTimeout(() => {
				this.tick($(e.currentTarget));
			});
		});

		this.dom.$window.on('load', (e) => {
			this.dom.$html.addClass('loaded');
			this.dom.$html.addClass('loaded-first');

			setTimeout(() => {
				this.dom.$html.removeClass('loaded-first')
			}, 4000);
		});
	}

	run() {
		this.tick(this.dom.$body);
		this.initModules();
		this.dom.$window.trigger('domready');
		this.dom.$html.addClass('domready');
	}

	initModules() {
		for (let i in this.modules) {
			this.modules[i].init();
		}
	}

	tick($context) {
		return new Promise((resolve) => {
		
			for (let selector in components) {
				this.initializeComponent(selector, $context.find('[data-' + selector + ']'));
			}

			resolve();
		});
	}

	initializeComponent(selector, $context) {
		$context.each((index, element) => {
			if (element['owd-component-' + selector] === undefined) {
				element['owd-component-' + selector] = new components[selector].component($(element), {}, this);
			}
		});
	}

	use(name, module) {
		this.modules[name] = new module(this);
		return this.modules[name];
	}

	getModule(name) {
		return this.modules[name];
	}
}

export default {
	Component: Component,
	Module: Module,
	App: App,

	registerComponent: (selector, meta) => {
		if (components.selector !== undefined) {
			throw 'Component for selector "' + selector + '" has been already registered.';
		}

		components[selector] = meta;
	}
};
