import { gsap } from 'gsap';

// Gets the mouse position
const getMousePos = e => {
    return {
        x: e.clientX,
        y: e.clientY
    };
};

// Linear interpolation
const lerp = (a, b, n) => (1 - n) * a + n * b;

// Track the mouse position
let mouse = { x: 0, y: 0 };
window.addEventListener('mousemove', ev => mouse = getMousePos(ev));

function fadeScr(dir){
    if(!(document.getElementById("scrPrompt").classList.contains("master-off"))){
        if(dir === "in"){
            for(let i = 0; i < scrPrompt.children.length; i++){
                let timing = ((i + .4) / 10)

                scrPrompt.children[i].style.transition = `opacity ${timing}s linear, transform ${(i + 2) * .01}s linear`
                scrPrompt.children[i].style.opacity = `1`
            }
    
        }else if(dir === "out"){
            for(let i = 0; i < scrPrompt.children.length; i++){
                let timing = ((i + .4) / 12)

                scrPrompt.children[scrPrompt.children.length - (i + 1)].style.transition = `opacity ${timing}s linear, transform ${(i + 2) * .01}s linear`
                scrPrompt.children[scrPrompt.children.length - (i + 1)].style.opacity = `0`
            }
        }
    }
}

export default class Cursor {
    constructor(el) {
        this.DOM = { el: el };
        this.DOM.el.style.opacity = 0;

        this.bounds = this.DOM.el.getBoundingClientRect();

        this.renderedStyles = {
            tx: { previous: 0, current: 0, amt: 0.2 },
            ty: { previous: 0, current: 0, amt: 0.2 },
            scale: { previous: 1, current: 1, amt: 0.2 },
            //opacity: {previous: 1, current: 1, amt: 0.1}
        };

        this.onMouseMoveEv = () => {
            this.renderedStyles.tx.previous = this.renderedStyles.tx.current = mouse.x - this.bounds.width / 2;
            this.renderedStyles.ty.previous = this.renderedStyles.ty.previous = mouse.y - this.bounds.height / 2;
            gsap.to(this.DOM.el, { duration: 0.9, ease: 'Power3.easeOut', opacity: 1 });
            requestAnimationFrame(() => this.render());
            window.removeEventListener('mousemove', this.onMouseMoveEv);
        };
        window.addEventListener('mousemove', this.onMouseMoveEv);
    }
    enter() {
        this.renderedStyles['scale'].current = .4;
        fadeScr("out")
        //this.renderedStyles['opacity'].current = 0.5;
    }
    leave() {
        this.renderedStyles['scale'].current = 1;
        fadeScr("in")
        //this.renderedStyles['opacity'].current = 1;
    }
    active() {
        this.renderedStyles['scale'].current = 1;
        //this.renderedStyles['opacity'].current = 1;
    }
    render() {
        this.renderedStyles['tx'].current = mouse.x - this.bounds.width / 2;
        this.renderedStyles['ty'].current = mouse.y - this.bounds.height / 2;

        for (const key in this.renderedStyles) {
            this.renderedStyles[key].previous = lerp(this.renderedStyles[key].previous, this.renderedStyles[key].current, this.renderedStyles[key].amt);
        }

        this.DOM.el.style.transform = `translateX(${(this.renderedStyles['tx'].previous)}px) translateY(${this.renderedStyles['ty'].previous}px) scale(${this.renderedStyles['scale'].previous})`;
        //this.DOM.el.style.opacity = this.renderedStyles['opacity'].previous;

        requestAnimationFrame(() => this.render());
    }
}
