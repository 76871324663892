import owd from '../../owd/owd';
import _ from 'underscore';

let lastMousePosition;

document.documentElement.addEventListener('mousemove', (e) => {
	lastMousePosition = {
		x: e.clientX,
		y: e.clientY
	};
});

export default class HoverPath extends owd.Component {
	init() {
		this.mouseTrail = false;

		this.readonlyMode = this.$element.data('touch-path') === 'readonly';

		this.$items = this.$element.find('[data-path-item]');
		this.$picture = this.$element.find('[data-path-picture]');
		this.$pictureHeight;
		this.$pictureWidth;
		this.$currItem;
		this.$currTitle
		this.$currPhoto;
		this.currItemPosTop;
		this.currItemPosLeft;

		this.scaleToHeight = !this.$element.is('[data-hover-scale-default]');

		var is_iPad = navigator.userAgent.match(/iPad/i) != null;
		if (is_iPad) {
			$('html').addClass('ipad');
		} else {
			$('html').removeClass('ipad');
		}

		if (window.matchMedia('(min-width: 1024px)').matches && !is_iPad) {
			this.initPixi();
		}

		this.bind();
		this.checkIfMouseInitiallyOver();
	}

	calc() {
		this.$pictureHeight = $(this.$picture[0]).outerHeight();
		this.$pictureWidth = $(this.$picture[0]).outerWidth();
	}

	checkIfMouseInitiallyOver() {
		this.$items.each((index, item) => {
			const bbox = item.getBoundingClientRect();

			if (lastMousePosition &&
				lastMousePosition.x >= bbox.left && 
				lastMousePosition.x <= bbox.left + bbox.width && 
				lastMousePosition.y >= bbox.top && 
				lastMousePosition.y <= bbox.top + bbox.height) {
				
				$(item).trigger('mouse-initially-over');
			}
		});
	}

	initPixi() {
		const width = window.innerWidth;
		const height = window.innerHeight;

		this.traceCanvas = document.createElement('canvas');
		this.traceCanvasCtx = this.traceCanvas.getContext('2d');
		this.traceCanvasCtx.canvas.width = width;
		this.traceCanvasCtx.canvas.height = height;

		PIXI.ticker.shared.autoStart = false;
        PIXI.ticker.shared.stop();

		this.pixiApp = new PIXI.Application(width, height, {
			transparent: true,
			antialias: true
		});
		this.pixiApp.ticker.autoStart = false;
		this.pixiApp.ticker.stop();
		this.pixiApp.renderer.autoResize = true
		this.$element[0].appendChild(this.pixiApp.view);

		this.pixiStage = new PIXI.Container();
		this.pixiApp.stage.addChild(this.pixiStage)
		this.pixiStage.interactive = false;

		this.maxDensity = window.innerWidth;
		this.opacityDensity = 800;
		this.currentOpacity = 0;

		this.combinedSprite = new PIXI.Sprite();
		this.combinedSprite.zOrder = -1;
		this.combinedSprite.position.x = 0;
		this.combinedSprite.position.y = 0;

		this.brush = new PIXI.Sprite();
		this.brush.anchor.set(0.5, 0.5)
		this.brush.interactive = false;
		
		if (lastMousePosition) {
			this.brush.position.x = lastMousePosition.x;
			this.brush.position.y = lastMousePosition.y;
		} else {
			this.brush.position.x = -window.innerWidth;
			this.brush.position.y = -window.innerHeight;
		}

		this.pixiApp.stage.interactive = true
		this.pixiApp.stage.on('pointermove', _.throttle((e) => {
			if (!this.readonlyMode) {
				this.moveTo(e.data.global);
			}
		}, 20));

		this.pixiStage.addChild(this.combinedSprite);
		this.combinedSprite.interactive = false;
		this.combinedSprite.texture = PIXI.Texture.fromCanvas(this.traceCanvas);

	}

	bind() {
		
		var is_iPad = navigator.userAgent.match(/iPad/i) != null;

		this.on('load resize', this.dom.$window, () => {
			this.calc();
		});

		if (window.matchMedia('(min-width: 1024px)').matches && !is_iPad) {
			this.on('resize', this.dom.$window, () => {
				this.resizeCanvas();
			});

			this.on('click', this.$items, 'a', (e) => {
				this.exitLock = true;
			});

			this.on('mouseenter mousemove mouse-initially-over', this.$items, (e) => {
				if (!this.exitLock && !this.readonlyMode && (e.type == 'mouseenter' || !this.firstMouseEvent)) {
					this.firstMouseEvent = true;

					this.$currItem = $(e.target).closest('[data-path-item]');
					this.$currPhoto = this.$currItem.find('[data-path-picture]');
					this.$currTitle = this.$currItem.find('[data-path-title]');

					this.$element.addClass('is-active');
					this.$currItem.addClass('is-above');
					this.$currTitle.addClass('is-highlighted');

					this.enableTrail(this.$currPhoto.find('img')[0]);
					this.isTrailEnabled = true;
				}
			});

			this.on('mouseenter mousemove', this.dom.$body, '[data-path-item]', (e) => {
				if (!this.exitLock && this.isTrailEnabled && !this.readonlyMode && !$.contains(this.$element[0], e.target)) {
					this.$currItem = $(e.target).closest('[data-path-item]');
					this.$currTitle = this.$currItem.find('[data-path-title]');

					this.$element.removeClass('is-active');
					this.$currTitle.removeClass('is-highlighted');
					this.$currItem.removeClass('is-above');

					this.disableTrail();
					this.isTrailEnabled = false;
				}
			});

			this.on('mouseleave', this.$items, (e) => {

				if (!this.exitLock) {
					this.$currItem = $(e.target).closest('[data-path-item]');
					this.$currTitle = this.$currItem.find('[data-path-title]');

					this.$element.removeClass('is-active');
					this.$currTitle.removeClass('is-highlighted');
					this.$currItem.removeClass('is-above');

					this.disableTrail();
					this.isTrailEnabled = false;
				}
			});

			this.on('enable-readonly', () => {
                this.readonlyMode = true;
            });
            
            this.on('disable-readonly', () => {
                this.readonlyMode = false;
            });
		}
	}

	resizeCanvas() {
		if (this.pixiApp) {
			// this.pixiApp.renderer.resize(window.innerWidth, window.innerHeight);
			this.traceCanvasCtx.canvas.width = window.innerWidth;
			this.traceCanvasCtx.canvas.height = window.innerHeight;
			this.maxDensity = window.innerWidth;
		}
	}

	enableTrail(sourceImage) {
		this.mouseTrail = true;

		this.clearTrail();
		this.$element.find('canvas').css('opacity', 1);

		const image = new Image;
		image.crossOrigin = 'anonymous';
		image.src = sourceImage.src;

		let previousTexture = this.texture;
		this.brush.texture = null;
		this.render();

		image.onload = () => {
			let width
			let height
			let proportion
			if(!(this.$element.hasClass("pageContent__projects"))){
				height = (Math.round(window.innerHeight * 0.7) / 3) * .8;
				width = height;
				
			}else{
				proportion = image.naturalHeight / image.naturalWidth;
				height = this.scaleToHeight ? Math.round(window.innerHeight * 0.7) : 500;
				width = this.scaleToHeight ? height / proportion : 280;
			}

			const texture = PIXI.Texture.from(image, {
				width,
				height,
			});
	
			this.canvasTexture = {
				image,
				width,
				height
			};

			if (this.brush.texture !== previousTexture) {
				if (previousTexture) {
					previousTexture.destroy();
				}
				
				previousTexture = this.brush.texture;
			}
	
			this.brush.width = width;
			this.brush.height = height;
			this.brush.texture = texture;
			this.brush.zOrder = 10;
			this.brush.anchor.set(0.5, 0.5)
			this.pixiStage.addChild(this.brush);
			this.texture = texture;

			if (lastMousePosition) {
				this.brush.position.x = lastMousePosition.x;
				this.brush.position.y = lastMousePosition.y;
			}
	
			setTimeout(() => {
				if (previousTexture) {
					previousTexture.destroy();
				}
			})
	
			this.render();
		};

	}

	disableTrail() {
		this.mouseTrail = false;
		this.brush.texture = null;
		this.currentOpacity = 0;
		
		if (this.canvasTexture) {
			this.canvasTexture.onload = null;
			this.canvasTexture = null;
		}

		this.clearTrail();
		this.render();
		this.$element.find('canvas').css('opacity', 0);
	}

	moveTo(position) {
		if (!this.previousPosition || !this.previousPosition.equals(position)) {
			if (this.mouseTrail) {
				let previousPosition = this.previousPosition ? this.previousPosition.clone() : undefined;
				setTimeout(() => {
					this.drawIntermediate(previousPosition, position);
				});
			}
	
			this.brush.position.copy(position);
			this.previousPosition = position.clone();
			
			this.render();
		}
	}

	limitPosition(position) {
		return new PIXI.Point(
			position.x,
			position.y
		);
	}

	paintBrush(position) {
		if (this.canvasTexture) {
			// this.traceCanvasCtx.save();
			// this.traceCanvasCtx.globalAlpha = this.currentOpacity;
			this.traceCanvasCtx.drawImage(
				this.canvasTexture.image,
				0, 
				0,
				this.canvasTexture.image.naturalWidth,
				this.canvasTexture.image.naturalHeight,
				position.x - this.canvasTexture.width/2,
				position.y - this.canvasTexture.height/2,
				this.canvasTexture.width,
				this.canvasTexture.height
			);
			// this.traceCanvasCtx.restore();
	
			// if (this.currentOpacity < 1) {
			// 	this.currentOpacity = Math.min(1, this.currentOpacity + 1/this.opacityDensity);
			// }
	
			this.render();
		}

	}

	render() {
        if (!this.af) {
            this.af = requestAnimationFrame(() => {
                this.af = null;
                if (this.pixiApp) {
					this.combinedSprite.texture.update();
                    this.pixiApp.render();
                }
            });
        }
    }

	drawIntermediate(prev, curr) {
		if (prev) {
			const density = this.calculateInterpolationDensity(prev, curr);

			if (density > 0) {
				for (let i=0; i<=density; i++) {
					this.paintBrush(this.interpolate(prev, curr, i/density));
				}
			} else {
				this.paintBrush(curr);
			}
		} else {
			this.paintBrush(curr);
		}
	}

	calculateInterpolationDensity(prev, curr) {
		const dx = Math.abs(prev.x - curr.x);
		const dy = Math.abs(prev.y - curr.y);

		return Math.round(
			Math.min(
				Math.max(dx, dy),
				this.maxDensity
			)
		);
	}

	interpolate(a, b, frac) {
		var nx = a.x+(b.x-a.x)*frac;
		var ny = a.y+(b.y-a.y)*frac;
		return new PIXI.Point(nx, ny);
	}

	clearTrail() {
		this.traceCanvasCtx.clearRect(
			0, 
			0, 
			this.traceCanvasCtx.canvas.width, 
			this.traceCanvasCtx.canvas.height
		);
	}

	destroy() {
		super.destroy();

		if (this.af) {
			cancelAnimationFrame(this.af);
		}

		if (this.canvasTexture) {
			this.canvasTexture.onload = null;
			this.canvasTexture = null;
		}

		if (this.pixiApp) {
			this.pixiApp.destroy({
				children: true,
				texture: true,
				baseTexture: true,
			});
			this.pixiApp = null
		}
	}
}

owd.registerComponent('hover-path', {
	component: HoverPath
});
